import { createCrudRoutes } from '@brain/core'

export default [
  ...createCrudRoutes('integrationAuthenticator', () =>
    import('../views/IntegrationAuthenticator')
  ),
  ...createCrudRoutes(
    'integrationModel',
    () => import('../views/IntegrationModel'),
    ['integrationExecutionLog', 'integrationOutbounds']
  ),
  ...createCrudRoutes('integrationInbound', () =>
    import('../views/IntegrationInbound')
  ),
  ...createCrudRoutes('integrationOutbound', () =>
    import('../views/IntegrationOutbound')
  ),
  ...createCrudRoutes('integrationTrigger', () =>
    import('../views/IntegrationTrigger')
  ),
  {
    path: '/integration-log',
    name: 'integrationLog',
    title: 'application.pages.integrationReportEventRaw.title',
    component: () => import('../views/IntegrationLog')
  }
]
