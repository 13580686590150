<template>
    <div v-if="data.possibleValues !== null">
        
        <bc-select
          v-model="selectedParameter"
          :items="parameters"
          item-text="text"
          item-value="value"
          :label="getGroupLabel()" 
          return-object          
        ></bc-select>
     
    </div>
  </template>
  
  <script>
  import { BcSelect,BcRow } from '@brain/ui'

  
  export default {
    name: 'ScenarioParameterSelect',
    components: { BcSelect, BcRow },
    data() {
      return {
        value: null,
        selectedParameter:{
          value: this.parameterData.value
        } 
      }
    },
    computed: {    
    parameters() {
      return this.getObject(this.data.possibleValues);
    },
    parameter() {
      return parameters.find(       
      parameter => parameter.value == this.selectedParameter
      );
    }
  },
    props: {
      parameterData: {
        type: Object,
        require: true
      }
    },
    created() {
      this.data = this.parameterData
    },
    methods: {
      getGroupLabel() {
        return this.$t(`application.parameters.items.${this.parameterData.code}`)
      },     
      getObject(possibleValue) {
       
       let array = []
       for(let i =0; i <possibleValue.length; i++ ){
          array.push({
            "text": this.$t(`application.parameters.options.${possibleValue[i]}`), 
            "value": possibleValue[i]
          })
       }
        return array 
      },
      getData() {
        return this.selectedParameter.value
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .group-text-color {
    color: var(--bc-toast-color);
  }
  </style>
  