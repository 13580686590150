export default {
  application: {
    enums: {
      integrationStatus: {
        started: 'Iniciada',
        authenticating: 'Autenticando',
        authenticated: 'Autenticado',
        authenticationError: 'Erro de autenticação',
        executingIntegrationInbound: 'Executando a entrada da integração',
        finishedIntegrationInbound: 'Entrada da integração concluída',
        integrationInboundError: 'Erro na entrada da integração',
        executingIntegrationOutbounds: 'Executando saídas da integração',
        integrationOutboundsError: 'Erro na saída da integração',
        finishedIntegrationOutbounds: 'Saídas da integração concluídas',
        finishedIntegration: 'Integração concluída',
        invalidSizeResponse: 'Response com quantidade de elementos inválida',
        error: 'Erro'
      }
    }
  },
  integrationService: {
    general: {
      integration: 'Integração'
    },
    pages: {
      integrationAuthenticator: {
        title: 'Autenticador de Integração',
        code: 'Código',
        service: 'Serviço',
        arguments: 'Argumentos'
      },
      integrationModel: {
        title: 'Integração',
        code: 'Código',
        integrationInbound: 'Entrada de Integração',
        arguments: 'Argumentos',
        isRunning: 'Executando',
        run: 'Executar',
        errors: {
          alreadyRunning: 'Integração já está executando',
          notFound: 'Integração não encontrada'
        },
        integrationExecutionLog: {
          title: 'Log de Execução de Integração',
          integration: 'Integração',
          user: 'Usuário',
          status: 'Estado',
          startDate: 'Início',
          endDate: 'Fim',
          statusCode: 'Código de Status',
          request: 'Requisição',
          response: 'Resposta'
        },
        integrationOutbounds: {
          title: 'Saídas de Integração',
          integration: 'Integração',
          integrationOutbound: 'Saída de Integração',
          order: 'Ordem'
        }
      },
      integrationInbound: {
        title: 'Entrada de Integração',
        code: 'Código',
        service: 'Serviço',
        authenticator: 'Autenticador',
        arguments: 'Argumentos'
      },
      integrationOutbound: {
        title: 'Saída de Integração',
        code: 'Código',
        service: 'Serviço',
        arguments: 'Argumentos'
      },
      integrationTrigger: {
        title: 'Gatilhos de Integração',
        integrationModel: 'Integração',
        triggerName: 'Nome',
        triggerGroup: 'Grupo',
        previousExecution: 'Ultima Execução',
        nextExecution: 'Próxima Execução',
        triggerState: 'Status',
        cronExpression: 'Frequência da Execução',
        cronFrequence: 'Frequência da Execução',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        edit: 'Editar',
        resume: 'Iniciar',
        pause: 'Pausar',
        delete: 'Deletar',
        startNow: 'Começar Agora',
        paused: 'Pausado'
      },
      integrationReportEventRaw: {
        title: 'Log de integração',
        integrationType: 'Tipo de Integração',
        entity: 'Entidade',
        event: 'Evento',
        message: 'Mensagem',
        key: 'Chave Única',
        validationEvent: 'Evento de Validação',
        rawDataJson: 'Dados (json)',
        date: 'Data',
        validationEventOptions: {
          noContractReferenceCode: 'Sem código de referência do contrato',
          noCompany: 'Sem companhia',
          noBillOfLandingDate: 'Sem bill of landing date',
          outdated: 'Desatualizado',
          noQuantityUnit: 'Sem unidade de quantidade',
          unknownContractCodePattern:
            'Padrão de código de contrato desconhecido',
          noClientDeliveryCode: 'Sem código de entrega do cliente',
          noCustomerFound: 'Cliente não encontrado',
          noDeliveryCountry: 'Sem país de entrega',
          noValidQuantity: 'Quantidade inválida',
          noValidInvoicedQuantity: 'Quantidade faturada inválida',
          noPortOfDestination: 'Sem porto de destino',
          noTerminalCode: 'Sem código de terminal',
          noInvoiceDate: 'Sem invoice date',
          noCropYear: 'Sem safra',
          noShippingLineCode: 'Sem shipping line code',
          noMaterial: 'Sem material',
          notExecuted: 'Não executado',
          noShippingPeriod: 'Sem período de envio',
          invalidShippingPeriod: 'Período de envio inválido',
          unknowMarketType: 'Tipo de mercado desconhecido',
          matchingRuleFound: 'Regra correspondente encontrada',
          openContractUpdate: 'Atualização de contrato aberto',
          unknownContract: 'Contrato desconhecido',
          intercompany: 'Intercompany',
          noPort: 'Sem porto',
          noPortCountry: 'Sem país do porto',
          cancelled: 'Cancelado',
          recap: 'Recap',
          reserved: 'Reservado',
          unknownWeightUnit: 'Unidade de peso desconhecida',
          noValidPrice: 'Sem preço válido',
          noPriceUnit: 'Sem unidade de preço',
          contractClaim: 'Sinistro',
          noPriceCurrency: 'Sem moeda de preço',
          unknownPriceCurrency: 'Moeda de preço desconhecida',
          noIncoterm: 'Sem incoterm',
          unknownInconterm: 'Inconterm desconhecido',
          noPositionMonth: 'Sem mês de posição',
          noShippingMonth: 'Sem mês de envio',
          noCounterpartySector: 'Sem counterparty sector',
          unknownCounterpartySector: 'Counterparty sector desconhecido',
          noCounterparty: 'Sem counterparty',
          executed: 'Executado',
          noMatchingRuleFound: 'Regra correspondente não encontrada',
          noMarketType: 'Sem tipo de mercado',
          allocated: 'Alocado',
          noStatus: 'Sem status',
          availableInventoryEmptyMarks: 'Estoque disponível, Marks vazio',
          availableInventoryWarehouseFarm: 'Estoque disponível em Fazenda',
          availableInventoryWarehouseStorage:
            'Estoque disponível em Armazém de Interior',
          availableInventoryWarehouseRailwayStuffingTerminal:
            'Estoque disponível em Terminal de Estufagem Ferroviário',
          availableInventoryWarehouseWaterwayStuffingTerminal:
            'Estoque disponível em Terminal de Estufagem Hidroviário',
          availableInventoryWarehousePortStuffingTerminal:
            'Estoque disponível em Terminal de Estufagem Portuário',
          unavailableInventoryUnknownWarehouse:
            'Estoque indisponível, Warehouse não identificado',
          duplicatedKey: 'Chave duplicada',
          unknownPurchaseContract: 'Contrato de compra desconhecido',
          unknownUsbucket: 'US Bucket desconhecido',
          unknownPurchaseContractCodePattern:
            'Padrão de código de contrato de compra desconhecido',
          unknownWarehouse: 'Armazém desconhecido',
          reservedForRecap: 'Reservado para recapitulação',
          internalMarket: 'Mercado interno',
          externalMarket: 'Mercado externo',
          allocatedInternalMarket: 'Mercado interno alocado',
          allocatedExternalMarket: 'Mercado externo alocado',
          notAllocated: 'Não alocado',
          reservedForContract: 'Reservado para contrato',
          noPurchaseContract: 'Sem contrato de compra',
          noLotId: 'Sem ID de lote',
          invalidLotReferenceValue: 'Valor de referência de lote inválido',
          noWarehouse: 'Sem armazém',
          noNetWeight: 'Sem peso líquido',
          noGrossWeight: 'Sem peso bruto',
          noWeightUnit: 'Sem unidade de peso',
          noBalesQuantity: 'Sem quantidade de fardos',
          noBalesPerContainer: 'Sem fardos por container',
          noInventoryMaterial: 'Sem material do estoque',
          noPurchaseMaterial: 'Sem material do contrato de compra',
          invalidCampimValue: 'Valor de campim inválido',
          invalidCauleValue: 'Valor de caule inválido',
          invalidFumaginaValue: 'Valor de fumagina inválido',
          invalidPicaoValue: 'Valor de pico inválido',
          invalidSementeValue: 'Valor de semente inválido',
          invalidMinimumLengthValue: 'Valor de comprimento mínimo inválido',
          invalidMinimumStrengthValue: 'Valor mínimo de força inválido',
          invalidMinimumMicronaireValue: 'Valor mínimo de micronaire inválido',
          invalidAverageLengthValue: 'Valor de comprimento médio inválido',
          invalidAverageStrengthValue: 'Valor de força média inválido',
          invalidAverageMicronaireValue: 'Valor médio de micronaire inválido',
          invalidMaximumLengthValue: 'Valor de comprimento máximo inválido',
          invalidMaximumStrengthValue: 'Valor de força máxima inválido',
          invalidMaximumMicronaireValue: 'Valor máximo de micronaire inválido',
          invalidShortFibreValue: 'Valor de fibra curta inválido',
          noBrazilDraftDeadlineDate: 'Sem data de deadline do draft do Brasil',
          noCargoDeadline: 'Sem data de deadline de carga',
          noBrazilPersonInCharge: 'Sem responsável do Brasil',
          noController: 'Sem controlador',
          noShipLineRef: 'Sem ship line ref',
          noEstimatedShipmentDate: 'Sem previsão de envio',
          noShippingLine: 'Sem shipping line',
          noJourneyNumber: 'Sem número de viagem',
          noVesselReference: 'Sem referência de embarcação',
          noDeliveryCountryCode: 'Sem código do país de entrega',
          noBrazilStatus: 'Sem status do Brasil',
          noRelevantStatus: 'Nenhum status relevante',
          invalidStatusForCreateInstruction:
            'Status inválido para criar instrução',
          instructionAlreadyExists: 'A instrução já existe',
          instructionNotFound: 'Instrução não encontrada',
          instructionOutdated: 'Instrução desatualizada',
          noInvoiceDueDate: 'Sem data de vencimento da fatura',
          noBillOfLadingDate: 'Sem data de conhecimento de embarque',
          unloadingNotFound: 'Descarga não encontrada',
          unloadingOutdated: 'Descarregamento desatualizado',
          loadingNotFound: 'Carregando não encontrado',
          loadingOutdated: 'Carregando desatualizado',
          documentationNotFound: 'Documentação não encontrada',
          documentationOutdated: 'Documentação desatualizada',
          reserveNotFound: 'Reserva não encontrada',
          reserveOutdated: 'Reserva desatualizada',
          reserveRequestNotFound: 'Solicitação de reserva não encontrada',
          reserveRequestOutdated: 'Solicitação de reserva desatualizada',
          noInstruction: 'Sem Instrução',
          noHaulierCode: 'Sem Código de Transportador',
          noArrivalMinimumDate: 'Sem Data Mínima de Chegada',
          noArrivalMaximumDate: 'Sem Data Máxima de Chegada',
          invalidArrivalPeriod: 'Data de chegada com periodo inválido',
          noUnloadingLine: 'Sem Linha de Descarga',
          noDriverLine: 'Sem Linha de Motorista',
          nonMatchingBatch: 'Lote Não Correspondente',
          driverOutdated: 'Motorista Desatualizado',
          matchingRuleFoundWithoutBatch:
            'Regra de Correspondência Encontrada Sem Lote',
          duplicateNewDriverLine: 'Nova Linha de Motorista Duplicada',
          unknownInstructionCodePattern:
            'Padrão de Código de Instrução Desconhecido',
          noLotVolume: 'Sem Volume de Lote',
          noLoadedLotReference: 'Sem Referência de Lote Carregado',
          noContractedLotReference: 'Sem Referência de Lote Contratado',
          noPlanId: 'Sem PlanId',
          noVehiclePlate: 'Veículo Sem Placa',
          invalidPurchCounterpartyTitle:
            'Título de Contraparte de Compra Inválido',
          noPremiumDiscountBrain:
            'Não existe Prêmio/Desconto para este contrato e lote',
          premiumDiscountBrainNotApproved:
            'O Prêmio/Desconto para este contrato e lote ainda não foi aprovado',
          premiumDiscountRegisteredOnItas:
            'Prêmio/Desconto foi registrado no ITAS',
          noImportsPreparationBrain:
            'Não existe Preparação de Imports para este contrato e lote',
          noArrivalDateAtOrigin: 'Sem Data de Chegada na Origem',
          noReserveLine: 'Sem Linha de Reserva',
          contractPrefixInvalid: 'Prefixo de Contrato Inválido',
          brazilPurchaseTermsFob: 'Incoterm FOB',
          finishedInstruction: 'Instrução finalizada',
          marksPrefixInvalid: 'Prefixo de Marks inválido',
          noCasaInstruction: 'Instrução não é CASA',
          noFobInstruction: 'Instrução não é FOB',
          fobControlNotFound: 'Controle FOB não encontrado',
          fobControlOutdated: 'Controle FOB desatualizado',
          noDestination: 'Sem Destino',
          invalidDestination:
            'Destino inválido (não é o terminal de estufagem)',
          notificationOldReadDate: 'Data de leitura antiga',
          invalidCounterparty: 'Counterparty inválido',
          unknownCounterPartyRef: 'Counter Party Ref desconhecido',
          instructionWithChangedIncoterm: 'Instrução com alteração de Incoterm',
          noExpectedStartLoadDate:
            'Sem Início data esperada carregamento origem',
          noExpectedEndLoadDate: 'Sem Fim data esperada carregamento origem',
          invalidExpectedLoadPeriod:
            'Data esperada carregamento origem com periodo inválido',
          noLotReference: 'Sem lote de referência',
          unknownDestination: 'Destino desconhecido',
          noIncotermDetail: 'Sem Incoterm Detalhe',
          containersWithdrawalNotFound: 'Retirada de Container não encontrada',
          containersWithdrawalOutdated: 'Retirada de Container desatualizada',
          matchingRuleFoundFreeGroups:
            'Regra correspondente encontrada para Free Groups',
          matchingRuleFoundWithErrorFreeGroups:
            'Regra correspondente encontrada com erro para Free Groups',
          finishedInstructionFreeGroups:
            'Instrução finalizada para Free Groups',
          noDriverLineFreeGroups: 'Sem Linha de Motorista',
          driverWithFreeGroupsCompleted: 'Motorista com Free Groups completo',
          sameContractWithLessInformation:
            'Um outro Contrato de Compra com mais informações foi encontrado',
          sameContractWithLessGroupNumber:
            'Um outro Contrato de Compra com Group Number maior foi encontrado',
          sameContractWithLessPlanId:
            'Um outro Contrato de Compra com Plan Id zero foi encontrado',
          invalidCommodityCodePart5: 'Part5 inválido',
          invalidCommodityCodePart5OrPart4: 'Part5 inválido ou Part4 inválido',
          notProgrammed: 'Não programado',
          matchingRuleFoundWithInvalidPremiumDiscount:
            'Regra correspondente encontrada com erro em Prêmio/Desconto',
          noBuyer: 'Sem Comprador',
          unknownBuyer: 'Comprador desconhecido',
          buyerNotDomesticMarket: 'Comprador não é do mercado doméstico',
          noSalesContract: 'Sem Contrato de Venda',
          unknownSalesContractCodePattern:
            'Padrão de código de contrato de venda desconhecido',
          unknownCompany: 'Companhia desconhecida',
          invalidWarehouseOrDestinationOrBuyer:
            'Warehouse, Destination ou Cliente inválido',
          warehouseNotStorage:
            'Warehouse não é Terminal de estufagem nem Armazém de interior',
          noWarehouseAndDestination: 'Sem Warehouse e Destination',
          unknownWarehouseAndDestination:
            'Warehouse e Destination desconhecidos',
          warehouseAndDestinationNotStorage:
            'Warehouse e Destination não são de armazenagem',
          hasAllocatedSale: 'Contém Venda Alocada',
          invalidWarehouseOrDestination: 'Warehouse ou Destination inválido',
          sameLogisticPortalKeyOfAnotherLine:
            'Existe uma linha de mesma chave que foi utilizada',
          invalidCostConversion: 'Conversão de custo inválida',
          unknownPortOfDestination: 'Porto de destino desconhecido',
          noBrazilDeliveryDeadlineDate: 'Sem Brazil Delivery Deadline Date',
          hasInvalidMarksForStorage: 'Marks não é válido para Armazenagem',
          noLogisticStorageLine: 'Sem linha de Armazenagem',
          contractMovedAfterFreeGroupsCompleted:
            'Contrato movido de instrução após Free Groups completo',

          purchaseDeletedInItas: 'Contrato de Compra deletado no ITAS',
          saleDeletedInItas: 'Contrato de Venda deletado no ITAS',
          bookingDeletedInItas: 'Booking deletado no ITAS',
          shipmentDeletedInItas: 'Shipment deletado no ITAS',
          inventoryDeletedInItas: 'Inventory deletado no ITAS'
        }
      }
    }
  }
}
