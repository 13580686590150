<template>
  <div class="parameter-field-container" v-show="!parameterData.hidden">
    <component
      :is="componentName"
      :parameterData="parameterData"
      :ref="parameterData.code"
    />
  </div>
</template>

<script>
import { BcCheckbox, BcTextField } from '@brain/ui'
import ScenarioParameterRadio from '../parameters-fields/ScenarioParameterRadio.vue'
import ScenarioParameterNumeric from '../parameters-fields/ScenarioParameterNumeric.vue'
import ScenarioParameterInteger from '../parameters-fields/ScenarioParameterInteger.vue'
import ScenarioParameterCheckbox from '../parameters-fields/ScenarioParameterCheckbox.vue'
import ScenarioParameterSelect from '../parameters-fields/ScenarioParameterSelect.vue'
export default {
  name: 'ScenarioParameterItem',
  components: {
    BcCheckbox,
    BcTextField,
    ScenarioParameterRadio,
    ScenarioParameterNumeric,
    ScenarioParameterInteger,
    ScenarioParameterCheckbox,
    ScenarioParameterSelect
  },
  props: {
    parameterData: {
      type: Object,
      require: true
    }
  },
  computed: {
    componentName() {
      if (this.parameterData.type === 'double') {
        return 'ScenarioParameterNumeric'
      }
      if (this.parameterData.type === 'decimal') {
        return 'ScenarioParameterNumeric'
      }
      if (this.parameterData.type === 'boolean') {
        return 'ScenarioParameterCheckbox'
      }

      if (this.parameterData.type === 'radio') {
        return 'ScenarioParameterRadio'
      }

      if (this.parameterData.type === 'integer') {
        return 'ScenarioParameterInteger'
      }
      if (this.parameterData.type === 'select') {
        return 'ScenarioParameterSelect'
      }
      return 'BcTextField'
    }
  },
  methods: {
    getData() {
      return String(this.$refs[this.parameterData.code].getData())
    }
  }
}
</script>

<style lang="scss" scoped>
.parameter-field-container {
  background: var(--bc-bg-filds);
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
}
</style>
