<template>
  <div>
    <div class="parameter-card-container">
      <bc-card color="#262c39" class="parameter-card">
        <span class="form-header__title">
          {{ getParameterGroupTitle(parameterGroup.code) }}
        </span>
      </bc-card>
    </div>
    <div class="col-margin">
      <bc-row class="text-center">
        <bc-col
          cols="6"
          v-for="parameter in parameterGroup.parameters"
          :key="parameter.code"
          class="padding-override"
        >
          <scenario-parameter-item
            :parameterData="parameter"
            :ref="parameter.code"
            v-if="!custom"
          />
          <div class="parameter" v-if="custom">
            <div>
              {{ getLabel(parameter) }}
            </div>
            <div class="parameter-value">
              <span v-if="parameter.type == 'boolean'">{{
                $t(`application.general.${parameter.value}`)
              }}</span>
              <span v-if="parameter.type == 'radio' || parameter.type == 'select'">{{
                $t(`application.parameters.options.${parameter.value}`)
              }}</span>
              <span
                v-if="parameter.type != 'boolean' && parameter.type != 'radio' && parameter.type!= 'select'"
                >{{ parameter.value }}</span
              >
            </div>
          </div>
        </bc-col>
      </bc-row>
    </div>
  </div>
</template>

<script>
import ScenarioParameterItem from './ScenarioParameterItem'
import { BcCard, BcCol } from '@brain/ui'

export default {
  name: 'ScenarioParameterGroupComponent',
  components: { ScenarioParameterItem, BcCard, BcCol },
  props: {
    parameterGroup: {
      type: Object,
      require: true
    },
    custom: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getParameterGroupTitle(parameterGroupCode) {
      return this.$t(`application.parameters.groups.${parameterGroupCode}`)
    },
    getUpdatedValues() {
      let parameterGroupCopy = this.parameterGroup
      parameterGroupCopy.parameters.forEach((parameter) => {
        parameter.value = this.$refs[parameter.code][0].getData()
      })
      return parameterGroupCopy
    },
    getLabel(_parameter) {
      return this.$t(`application.parameters.items.${_parameter.code}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-header__title {
  text-align: left;
  font: bold 14px Aller;
  letter-spacing: 0px;
  color: var(--bc-title-color);
  text-transform: uppercase;
}

.parameter-card {
  padding: 12px 20px;
}

.parameter-card-container {
  padding: 20px;
}

.parameter {
  padding: 12px 20px;
  background: #262c39;
  color: #959db5;
  text-align: left;
}

.parameter-value {
  color: #24bbef;
}

.padding-override {
  padding: 5px !important;
}

.col-margin {
  margin-left: 25px;
  margin-right: 25px;
}

.parameter-field-container {
  background: var(--bc-bg-filds);
  margin-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
}
</style>
